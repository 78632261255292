import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ConfirmClick from '../../../common/ConfirmClick';
import Spinner from '../../../common/Spinner';
import Tooltip from '../../../common/Tooltip';
import AbsoluteValue from '../../../common/AbsoluteValue';
import CopyToClipboard from '../../../common/CopyToClipboard';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../../common/Datagrid/Footers';
import { convertToThousands } from  '../../../../helpers/NumberFormatter';

export const tiktokMetricsSettings = {
    settingsForTable
};

function getLogoCell(item, entity) {
    return 
}

function settingsForTable(channelTypes, refresh){
    let columns = [
       {
           id: "logo",
           className: "logo-sell",
           headerClassName: "logo-sell no-sort",
           Header: "",
           accessor: "image",
           width: 60,
           sortable: false,
           Cell: (props) => {
               return (
                   <div className="logo-container">
                       <Link to={`/tracks/${props.original.track_id}`}>
                           <div
                               className="logo"
                               style={{ backgroundImage: `url(${props.value})` }}
                           />
                       </Link>
                   </div>
               );
           },
           Footer: spaceFooter
       },
                  
       {
           id: 'name',
           className: "imprint-name",
           headerClassName: "imprint-name no-sort",
           sortable: false,
           Header: 'Title',
           accessor: 'name',
           Cell: props => {
               return <span><span><b>{props.original.track_id ? <Link to={`/tracks/${props.original.track_id}`}>{props.value}</Link> : props.value}</b></span>
                   By: {props.original.artist}
                   <span className="isrc-holder isrc-holder-inline">ISRC: {props.original.isrc}
                   {props.original.track_id ? <CopyToClipboard value={props.original.isrc} /> : null}</span>
                   {/* Duration: {getTime(props.original.duration)} */}
               </span>
           },
           Footer: textFooter,
           minWidth: 250

       },
       /*
       {
           id: 'curr_units',
           className: '',
           Header: 'Units',
           accessor: 'curr_units',
           width: 100,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />,
       },
       */       
       {
           id: 'views',
           className: '',
           Header: props => <div className="top-table-header-inner">
               Views
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`We are not able to display this currently at product level in order to protect against data duplications. Please view these at the Track level.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
            </div>,
           accessor: 'views',
           width: 160,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />
       },
       {
           id: 'creates',
           className: '',
           Header: props => <div className="top-table-header-inner">
               Creates
               <div className="table-header-tooltip">
                    <Tooltip position="bottom" message={`We are not able to display this currently at product level in order to protect against data duplications. Please view these at the Track level.`} tooltipClass="toolbar-title-tooltip"/>
                </div>
                </div>,

           accessor: 'creates',
           width: 160,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />
       },       
       {
           id: 'likes',
           className: '',
           Header: 'Likes',
           accessor: 'likes',
           width: 160,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />
       },
       {
           id: 'shares',
           className: '',
           Header: 'Shares',
           accessor: 'shares',
           width: 160,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />
       },       
       {
           id: 'saves',
           className: '',
           Header: 'Saves',
           accessor: 'saves',
           width: 160,
           Footer: convertSumFooter,
           Cell: props => <AbsoluteValue value={props.value} />           
       }       
       ];    
    return columns;
}